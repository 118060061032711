import * as types from "./../types";
import { combineReducers } from "redux";

function currentId(state = null, action = {}) {
  switch (action.type) {
    case types.WL_SET_CURRENT_ID:
      typeof window !== "undefined" &&
        window.localStorage.setItem("watchlistId", action.watchlistId);
      return action.watchlistId;
    default:
      return state;
  }
}

// create|edit|delete
function modal(state = null, action = {}) {
  switch (action.type) {
    case types.WL_SET_MODAL:
      return action.name;
    default:
      return state;
  }
}

function collapsed(state = true, action = {}) {
  switch (action.type) {
    case types.WL_SET_COLLAPSED:
      typeof window !== "undefined" &&
        window.localStorage.setItem("watchlistIsCollapsed", action.collapsed);
      return action.collapsed;
    default:
      return state;
  }
}

function sortField(state = "Ticker", action = {}) {
  switch (action.type) {
    case types.WL_SET_SORT_FIELD:
      typeof window !== "undefined" &&
        window.localStorage.setItem("sortField", action.field);
      return action.field;
    default:
      return state;
  }
}

function sortOrder(state = "desc", action = {}) {
  switch (action.type) {
    case types.WL_SET_SORT_ORDER:
      typeof window !== "undefined" &&
        window.localStorage.setItem("sortOrder", action.order);
      return action.order;
    default:
      return state;
  }
}

export default combineReducers({
  currentId,
  modal,
  collapsed,
  sortField,
  sortOrder
});
