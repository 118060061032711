import React from "react";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";

import PageMeta from "components/PageMeta";
import ContentOverlay from "components/SingleContent";
import { Post } from "components/TimelinePost";

const mapStateToProps = (state, { id }) => ({
  post: state.chronology.posts[id],
});

const mapDispatchToProps = (dispatch) => ({
  getItem: (id) => dispatch(Actions.chronology.fetchSingle(id)),
});

class Content extends React.Component {
  componentDidMount() {
    this.props.getItem(this.props.id);
  }

  shouldComponentUpdate(nextProps) {
    return (
      (!this.props.post && nextProps.post) ||
      (this.props.post && nextProps.post.id !== this.props.post.id)
    );
  }

  render() {
    const { onClose, post } = this.props;

    if (!post) {
      return null;
    }    

    return (
      <>
        <PageMeta url={`https://www.voxmarkets.co.uk/media/${post.id}/`} />
        <ContentOverlay onClose={onClose} width={60}>
          <Post {...post} single />
        </ContentOverlay>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Content);
