import * as types from "./../types";

const initialState = false;

const isRouter = (state = initialState, action) => {
  switch (action.type) {
    case types.IS_ROUTER:
      return action.status;
    default:
      return state;
  }
};

export default isRouter;
