import * as types from "./../types";

const initialState = false;

const isServer = (state = initialState, action) => {
  switch (action.type) {
    case types.IS_SERVER:
      return action.status;
    default:
      return state;
  }
};

export default isServer;
