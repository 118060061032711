import * as types from "./../types";

const initialState = false;

const loginOverlayReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOGIN_OVERLAY:
      return action.status;
    default:
      return state;
  }
};

export default loginOverlayReducer;
