import * as types from "./../types";

const initialState = {
  rnsDisplayList: [],
  rnsLoadingNew: false,
  rnsUpdates: []
};

const removeDuplicateRNS = (updates, existing) => {
  const existingIds = existing.map(item => item.id);
  return updates.filter(item => existingIds.indexOf(item.id) === -1);
};

const rnsui = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_RNS_FILTER:
      return {
        ...state,
        rnsFilter: action.result,
        rnsLoadingNew: true
      };
    case types.SET_RNS_LIST:
      return {
        ...state,
        rnsDisplayList: action.result,
        rnsLoadingNew: false
      };
    case types.SET_RNS_UPDATES:
      return {
        ...state,
        rnsUpdates: removeDuplicateRNS(action.result, state.rnsDisplayList)
      };
    case types.APPEND_RNS_LIST:
      return {
        ...state,
        rnsDisplayList: [
          ...state.rnsDisplayList,
          ...removeDuplicateRNS(action.result, state.rnsDisplayList)
        ]
      };
    case types.PREPEND_RNS_LIST:
      return {
        ...state,
        rnsDisplayList: [...state.rnsUpdates, ...state.rnsDisplayList],
        rnsUpdates: []
      };
    default:
      return state;
  }
};

export default rnsui;
