import * as types from "./../types";
import { combineReducers } from "redux";

//--------------------
function sectorIds(state = [], action = {}) {
  switch (action.type) {
    case types.MARKET_INDEX_SECTOR_SET: //toggle
      if (state.includes(action.sectorId))
        return state.filter(c => c !== action.sectorId);
      return [...state, action.sectorId];
    default:
      return state;
  }
}

export default combineReducers({
  sectorIds
});
