import { v2API } from "@voxmarkets/api-sdk";
import { Actions } from "@voxmarkets/vox-reducers";
import * as types from "./../types";

export function setQuery(query) {
  return {
    type: types.SEARCH_SET_QUERY,
    query
  };
}

export function setResults(hits, total, query, searchType, from) {
  return {
    type: types.SEARCH_FETCH,
    hits,
    total,
    query,
    searchType,
    from
  };
}

export function fetch(query, searchType, from = 0) {
  return (dispatch, getState) =>
    v2API.FullSearch.runSearch(query, searchType, from)
      .then(result => {
        const state = getState();
        //if input in query not equal results from server, do not update state
        return state.ui.search.query !== query
          ? Promise.resolve()
          : dispatch(
              setResults(
                result.hits.hits,
                result.hits.total,
                query,
                searchType,
                from
              )
            );
      })
      .catch(e => {
        console.log("report: error empty results");
        return dispatch(setResults([], 0, query, searchType, from));
      });
}

export function companies(query, limit = 50) {
  return (dispatch, getState) =>
    dispatch(
      Actions.advCompanies.companies.getList({
        where: { companyName: { like: query, options: "i" } },
        limit
      })
    )
      .then(result => result.result || Promise.reject())
      .then(companies => companies.map(company => company.id))
      .then(companyIds =>
        dispatch(
          Actions.advCompanies.issues.getList({
            where: { issueCompanyId: { inq: companyIds } }
          })
        )
      )
      .then(result => result.result || Promise.reject())
      .then(issues => issues.map(issue => issue.id))
      .then(issueIds =>
        dispatch(Actions.advCompanies.listings.getByIssueIds(issueIds))
      )
      .then(result => {
        const state = getState();
        //if input in query not equal results from server, do not update state
        return state.ui.search.query !== query
          ? Promise.resolve()
          : dispatch(setResults([], 0, query, "companies", 0));
      })
      .catch(error => {
        console.error(error);
      });
}

//test hzm
export function listings(query) {
  return (dispatch, getState) =>
    dispatch(Actions.advCompanies.listings.getByShortTicker(query))
      .then(result => {
        console.log('listing search', result);
        return result.result || Promise.reject()
      })
      .then(listings => listings.map(listing => listing.issueId))
      .then(issueIds =>
        dispatch(Actions.advCompanies.issues.getByIds(issueIds))
      )
      .then(result => result.result || Promise.reject())
      .then(issues => issues.map(issue => issue.issueCompanyId))
      .then(companyIds =>
        dispatch(Actions.advCompanies.companies.getByIds(companyIds))
      )
      .then(result => {
        const state = getState();
        //if input in query not equal results from server, do not update state
        return state.ui.search.query !== query
          ? Promise.resolve()
          : dispatch(setResults([], 0, query, "listings", 0));
      })
      .catch(error => {
        console.error(error);
      });
}

export function resetResults(query) {
  return {
    type: types.RESET_RESULTS,
    query
  };
}
