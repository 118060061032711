import { Actions, ActionTypes } from "@voxmarkets/vox-reducers";

const VoxItem = url => {
  const HOST = "https://www.voxmarkets.co.uk";
  if (url.indexOf(HOST) === 0) {
    const path = url.replace(HOST, "").split("?");
    let pathname = path[0];

    if (path.length > 1 && path[1].indexOf("mediaAssetId=") > -1) {
      let mediaId = path[1]
        .replace(/^.*mediaAssetId=/, "")
        .replace(/([A-z0-9]+)(\w*.*)$/, "$1");
      if (mediaId) {
        pathname = `/media/${mediaId}`;
      }
    }
    return {
      pathname
    };
  }
  return false;
};

const getMeta = ({ scope, params, ...rest }) => {
  return new Promise((resolve, reject) => {
    resolve({ title: "", ...rest });
  });
};

export function getPreview(url) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.FTC_PREVIEW_RQ,
      url
    });
    const internal = VoxItem(url);
    if (internal) {
      return getMeta(internal)
        .then(meta => {
          return dispatch({
            type: ActionTypes.FTC_PREVIEW_OK,
            url,
            meta
          });
        })
        .catch(err => {
          return dispatch({
            type: ActionTypes.FTC_PREVIEW_FAI,
            url
          });
        });
    }
    return dispatch(Actions.previews.fetchPreview(url));
  };
}
