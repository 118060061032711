import * as types from "./../types";

//--------------------
export default function preferencesReducer(
  state = { pending: {} },
  action = {}
) {
  switch (action.type) {
    case types.PREFERENCE_SET:
      const pending = Object.keys(state.pending)
        .filter((k) => k !== action.key)
        .map((k) => state.pending[k]);
      return { ...state, [action.key]: action.value };
    case types.PREFERENCE_GET:
      return {
        ...state,
        pending: { ...state.pending, [action.key]: action.promise },
      };
    default:
      return state;
  }
}
