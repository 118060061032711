import * as types from "./../types";

const appRightMargin = (state = 0, action) => {
  switch (action.type) {
    case types.APP_RIGHT_MARGIN:
      return action.px;
    default:
      return state;
  }
};

export default appRightMargin;
