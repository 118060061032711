import * as types from "./../types";
import { combineReducers } from "redux";

//--------------------
function completed(state = false, action = {}) {
  switch (action.type) {
    case types.AUTHENTICATION_COMPLETED:
      return action.status;
    default:
      return state;
  }
}

export default combineReducers({
  completed
});
