import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import PageMeta from "components/PageMeta";
import ContentOverlay from "components/SingleContent";
import Interactions from "components/Interactions";
import { atmedia } from "components/VoxTheme";

import SeriesHeader from "features/series/components/SeriesHeader";
import MediaItem from "features/media-assets/components/MediaItem";
import { useMediaAsset } from "features/media-assets/queries";

import { getCanonicalUrl } from "meta";

const HeaderWrap = styled.div`
  margin: -2.5rem -2rem 2rem;
  background: url("https://s3-eu-west-1.amazonaws.com/vox.store.images/user-cover/profile-bg-2.jpg")
    50% 50% / cover;
  min-height: 6rem;
  ${atmedia.mobile`
    min-height: 4rem;
    margin-bottom: 0.5rem;
    > * { display:none }
  `}
`;

const InteractionWrap = styled.div`
  max-width: 600px;
  margin: 2em auto;
`;

const MediaItemWrap = styled.div`
  ${atmedia.mobile`
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  `}
`;

export default function MediaAssetPage({ onClose, context }) {
  const { mediaId: mediaAssetId } = useParams();
  const { data: mediaAsset } = useMediaAsset(mediaAssetId);

  let link = "";
  if (context && context !== "/") {
    link = context;
  }

  if (!mediaAsset) {
    return null;
  }
  return (
    <>
      <PageMeta
        title={`${mediaAsset.mediaTitle} - Vox Markets`}
        image={mediaAsset.mediaMetadata.thumbnailUrl}
        url={`https://www.voxmarkets.co.uk/media/${mediaAsset.id}/`}
      />
      <ContentOverlay onClose={onClose} width={60}>
        <HeaderWrap>
          <SeriesHeader seriesId={mediaAsset.mediaSeriesId} titleAs="h2" />
        </HeaderWrap>
        <MediaItemWrap>
          <MediaItem
            mediaAsset={mediaAsset}
            link={link}
            onOverlay={true}
            key="player"
            titleAs="h1"
          />
        </MediaItemWrap>
        <InteractionWrap>
          <Interactions
            id={mediaAssetId}
            showComments={true}
            shareText={mediaAsset.mediaTitle}
            scope={{ mediaAssetId }}
            url={`${getCanonicalUrl(mediaAsset, "media")}${
              link ? `?context=${link}` : ``
            }`}
          />
        </InteractionWrap>
      </ContentOverlay>
    </>
  );
}
