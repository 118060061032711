import * as types from "./../types";
import { legacyAPI } from "@voxmarkets/api-sdk";

export function getPreference(key, force = false) {
  return (dispatch, getState) => {
    const pending = Object.keys(getState().ui.preferences.pending).find(
      k => k === key
    );
    if (pending) {
      return pending;
    }
    const existing = getState().ui.preferences[key];
    if (existing && !force) {
      return Promise.resolve({
        key,
        existing
      });
    }
    const promise = legacyAPI.Users.getMeta(key);
    dispatch({
      type: types.PREFERENCE_GET,
      key,
      promise
    });
    return promise.then(resp => {
      let value = false;
      if (resp && resp.length === 1) {
        value = resp[0].meta_value;
      }

      return dispatch({
        type: types.PREFERENCE_SET,
        key,
        value
      });
    });
  };
}
