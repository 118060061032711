import * as types from "./../types";

export function appendPost(post) {
  return {
    type: types.TIMELINE_UPDATES_APPEND,
    post
  };
}

export function flushUpdates(post) {
  return {
    type: types.TIMELINE_UPDATES_FLUSH,
    post
  };
}
