import * as types from "./../types";

const initialState = {};

export default function feedbackReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FEEDBACK_ADD:
      return Object.assign({}, state, {
        text: action.text,
        status: action.status
      });
    case types.FEEDBACK_CLEAR:
      return {};
    default:
      return state;
  }
}
