import React from "react";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import BB from "./utils/bbparse";

export const getTitle = (prop, context, fallback) => {
  if (!prop) {
    return fallback;
  }
  if (prop.hasOwnProperty("companyName") && prop.hasOwnProperty("ticker")) {
    return `${prop.ticker} | ${
      prop.companyName
    } | Share Prices & News In One Place - Vox Markets`;
  } else if (context === "meta" && prop.hasOwnProperty("storyHeadline")) {
    return `${prop.storyHeadline} - Vox Markets` || fallback;
  } else if (context === "media") {
    return `${prop.mediaTitle} - Vox Markets` || fallback;
  } else if (context === "marketIndex") {
    return (
      `${prop.indexName} - Listing Prices & News | Vox Markets` || fallback
    );
  } else if (context === "post") {
    return (
      `${prop.authorName}: ${BB.strip(prop.postContent).substr(
        0,
        30
      )}... - Vox Markets` || fallback
    );
  }
  return fallback;
};

const getImage = (
  prop,
  context,
  fallback = "https://s3-eu-west-1.amazonaws.com/vox.assets.public/auth0/logo.png"
) => {
  if (!prop) {
    return fallback;
  }
  if (context === "author" && prop.hasOwnProperty("avatar")) {
    return prop.avatar || fallback;
  } else if (context === "attachmentImage") {
    return prop.postContent;
  } else if (context === "company" && prop.hasOwnProperty("id")) {
    return `https://img.voxmarkets.co.uk/1024/companies/${prop.id}.png`;
  } else if (context === "issue" && prop.hasOwnProperty("issueCompanyId")) {
    return `https://img.voxmarkets.co.uk/1024/companies/${prop.companyId}.png`;
  } else if (context === "media" && prop.hasOwnProperty("mediaMetadata")) { // remove
    return prop.mediaMetadata.thumbnailUrl || fallback;
  } else if (context === "marketIndex" && prop.hasOwnProperty("image")) {
    return prop.image || fallback;
  }
  return fallback;
};

const getDescription = (prop, context, fallback) => {
  if (!prop) {
    return fallback;
  }
  if (context === "meta" && prop.hasOwnProperty("summary")) {
    return prop.summary;
  } else if (context === "marketIndex") {
    return (
      `All ${prop.indexName} Companies, Prices and News in One Place` ||
      fallback
    );
  }
  return fallback;
};

export const getCanonicalUrl = (prop, context, url) => {
  if (!prop) {
    return url;
  } else if (context === "article") {
    url = `/articles/${prop.articleLeafname}/`;
  } else if (context === "meta") {
    url = `/rns/announcement/${prop.storyId}/`;
  } else if (context === "company" && prop.hasOwnProperty("ticker")) {
    url = `/company/${prop.ticker.replace(/\..+$/, "")}/`;
  } else if (context === "media") {
    url = `/media/${prop.id}/`;
  } else if (context === "post") {
    url = `/post/${prop.id}/`;
  } else if (context === "marketIndex") {
    url = `/index/${prop.indexLeafname}/`;
  }
  else if( typeof prop === "string") {
    url = prop;
  }
  if (url && url.indexOf("/") === 0) {
    url = `https://www.voxmarkets.co.uk${url}`;
  }
  return url;
};

export const getLinkedData = (prop, context, data = {}, store) => {
  if (!prop) {
    return data;
  }
  if (context === "article") {
    data = Object.assign({}, data, {
      mainEntityOfPage: {
        "@type": "webpage",
        "@id": `https://www.voxmarkets.co.uk/articles/${prop.articleLeafname}/`
      },
      headline: prop.articleTitle,
      description: prop.articleSummary,
      datePublished: format(
        new Date(prop.publishedAt),
        "yyyy-MM-dd'T'HH:mm:ssZ"
      ),
      dateModified: format(new Date(prop.updatedAt), "yyyy-MM-dd'T'HH:mm:ssZ"),
      image: {
        "@type": "ImageObject",
        url: prop.articleFeaturedImageUrl
      }
    });
  }
  if (context === "author") {
    data = Object.assign({}, data, {
      author: {
        "@type": "Person",
        name: prop.name || "Vox Markets Staff Writer"
      }
    });
  }
  if (context === "meta") {
    let author = {};
    if (store.hasOwnProperty("company") && typeof store.company == "object") {
      author = {
        "@type": "Organization",
        legalName: store.company.companyName || "",
        name: store.company.companyName || "Vox Markets",
        logo: getImage(store.company, "company")
      };
    } else {
      author = {
        "@type": "Organization",
        name: "Vox Markets",
        logo:
          "https://s3-eu-west-1.amazonaws.com/vox.assets.public/auth0/logo.png"
      };
    }
    data = Object.assign({}, data, {
      mainEntityOfPage: {
        "@type": "webpage",
        "@id": `https://www.voxmarkets.co.uk/rns/announcement/${prop.storyId}/`
      },
      headline: prop.storyHeadline,
      author,
      image: {
        "@type": "ImageObject",
        url: getImage(store.company, "company")
      },
      datePublished: format(new Date(prop.date), "yyyy-MM-dd'T'HH:mm:ssZ"),
      dateModified: format(new Date(prop.date), "yyyy-MM-dd'T'HH:mm:ssZ")
    });
  }
  if (context === "company" && prop.hasOwnProperty("ticker")) {
    data = {
      "@context": "https://schema.org/",
      "@type": "Corporation",
      tickerSymbol: prop.ticker,
      description: prop.companyProfile,
      legalName: prop.companyName,
      logo: getImage(prop, "company"),
      url: getCanonicalUrl(prop, "company")
    };
    if (prop.hasOwnProperty("location") && prop.location !== null) {
      let addr = prop.location;
      data = Object.assign(data, {
        location: {
          "@type": "PostalAddress",
          addressCountry: addr.locationISOCountry,
          addressLocality: addr.locationCity,
          addressRegion: addr.locationState,
          postalCode: addr.locationPostalCode,
          streetAddress: [
            addr.locationStreet1,
            addr.locationStreet2,
            addr.locationStreet3
          ].join(" ")
        }
      });
    }
    if (prop.hasOwnProperty("twitterHandle") && prop.twitterHandle) {
      data = Object.assign(data, {
        sameAs: [
          `https://twitter.com/${prop.twitterHandle.replace("@", "")}`.trim()
        ]
      });
    }
  }

  return data;
};

const matchBestMeta = (store, predefined = {}) => {
  const fallback = Object.assign(
    {
      url: `https://www.voxmarkets.co.uk`,
      image: `https://www.voxmarkets.co.uk/large.png`,
      title: `Vox Markets - Market Investor News, Insights & Analysis`,
      description:
        "Real time Market investment news, RNS feeds, watchlists & expert insight from Fund Managers, CEO’s & Analysts. Vox Markets the definitive source for AIM Market news, insight & analysis.",
      keywords: [],
      ld: {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        publisher: {
          "@type": "Organization",
          name: "Vox Markets",
          logo: {
            "@type": "ImageObject",
            url:
              "https://s3-eu-west-1.amazonaws.com/vox.assets.public/auth0/logo.png"
          }
        }
      }
    },
    predefined
  );
  const matches = Object.keys(store).reduce((matches, key) => {
    return Object.assign(matches, {
      description: getDescription(store[key], key, matches.description),      
      image: getImage(store[key], key, matches.image),
      title: getTitle(store[key], key, matches.title),
      url: getCanonicalUrl(store[key], key, matches.url),
      ld: getLinkedData(store[key], key, matches.ld, store)
    });
  }, fallback);
  return matches;
};

const Meta = ( props ) => {
  if (Object.keys(props).length === 0) {
    return null;
  }

  const {
    title,
    description,    
    image,
    url,
    ld,
    type = "article",
  } = matchBestMeta(props);
  const { preload } = props;

    return (
      <Helmet>
        <title>{title}</title>
        <meta property="og:type" content={type} />
        <meta property="og:image" content={image} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Vox Markets" />
        <meta name="twitter:card" content={(image && (image.includes('/1920/cms/') || image.includes(`public-media/videos`))) ? `summary_large_image` : `summary` } />
        <meta name="twitter:site" content="@voxmarkets" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:image:alt" content={title} />      
        {url && <meta property="og:url" content={url} />}
        <script type="application/ld+json">{JSON.stringify(ld)}</script>
        { preload && preload.map( item => <link rel="preload" as={item.as} href={item.href} key={item.href} /> )}
      </Helmet>
    );
};

export default Meta;
