import * as types from "./../types";

export function setCurrentId(watchlistId) {
  return {
    type: types.WL_SET_CURRENT_ID,
    watchlistId
  };
}

export function showModal(name) {
  return {
    type: types.WL_SET_MODAL,
    name
  };
}

export function hideModal(name) {
  return {
    type: types.WL_SET_MODAL,
    name: ""
  };
}

export function setCollapsed(collapsed) {
  return {
    type: types.WL_SET_COLLAPSED,
    collapsed
  };
}

export function setSortField(field) {
  return {
    type: types.WL_SET_SORT_FIELD,
    field
  };
}

export function setSortOrder(order) {
  return {
    type: types.WL_SET_SORT_ORDER,
    order
  };
}
