export const SHOW_LOGIN_OVERLAY = "@@web/show-login-overlay";
export const IS_SERVER = "@@web/is_server";
export const IS_ROUTER = "@@web/is_router";
export const SET_RNS_FILTER = "@@web/set-rns-index";
export const SET_RNS_LIST = "@@web/set-rns-list";
export const SET_RNS_UPDATES = "@@web/set-rns-updates";
export const APPEND_RNS_LIST = "@@web/append-rns-list";
export const PREPEND_RNS_LIST = "@@web/prepend-rns-list";
export const FEEDBACK_ADD = "@@web/feedback-add";
export const FEEDBACK_CLEAR = "@@web/feedback-clear";
export const NAVIGATION_GO = "@@web/navigation-go";
export const NAVIGATION_UPDATE_DOCUMENT_TITLE =
  "@@web/navigation-update-document-title";

export const WL_SET_CURRENT_ID = "@@web/watchlist-set-current-id";
export const WL_SET_MODAL = "@@web/watchlist-set-modal";
export const WL_SET_COLLAPSED = "@@web/watchlist-set-collapsed";
export const WL_SET_SORT_FIELD = "@@web/watchlist-set-sort-field";
export const WL_SET_SORT_ORDER = "@@web/watchlist-set-sort-order";

export const TIMELINE_UPDATES_APPEND = "@@web/timeline-updates-append";
export const TIMELINE_UPDATES_FLUSH = "@@web/timeline-updates-flush";

export const PREVIEW_GET = "@@web/preview-get";
export const PREVIEW_SET = "@@web/preview-set";

export const SEARCH_SET_QUERY = "@@web/search_set_querye";
export const SEARCH_FETCH = "@@web/search_fetch";
export const RESET_RESULTS = "@@web/reset_results";

export const APP_RIGHT_MARGIN = "@@web/app-right-margin";

export const AUTHENTICATION_COMPLETED = "@@web/authentication_completed";

export const MARKET_INDEX_SECTOR_SET = "@@web/market_index_sector_set";

export const PREFERENCE_GET = "@@web/preference-get";
export const PREFERENCE_SET = "@@web/preference-set";

export const CLIENT_SET_WINDOW_WIDTH = "@@web/client-set-window-width";
