import * as types from "./../types";

export function setRNSFilter({
  marketIndexId,
  type,
  query,
  watchedCompanies,
  before
}) {
  return {
    type: types.SET_RNS_FILTER,
    result: {
      marketIndexId: marketIndexId || "",
      type: type || "",
      before,
      query,
      watchedCompanies: (marketIndexId || type) ? false : watchedCompanies
    }
  };
}

export function setRNSDisplayList(news = {}) {
  return {
    type: types.SET_RNS_LIST,
    result: Object.keys(news).map(key => news[key])
  };
}

export function setRNSUpdates(news = {}) {
  return {
    type: types.SET_RNS_UPDATES,
    result: Object.keys(news).map(key => news[key])
  };
}

export function appendRNSDisplayList(news = {}) {
  return {
    type: types.APPEND_RNS_LIST,
    result: Object.keys(news).map(key => news[key])
  };
}

export function prependRNSDisplayList(list = []) {
  return {
    type: types.PREPEND_RNS_LIST,
    result: list
  };
}
