import * as types from "./../types";
import { combineReducers } from "redux";

//--------------------
function query(state = "", action = {}) {
  switch (action.type) {
    case types.SEARCH_SET_QUERY:
      return action.query;
    default:
      return state;
  }
}

//---------------------------
const initType = {
  hits: [],
  total: 0,
  query: ""
};

function createTypeReducer(type) {
  return function searchType(state = initType, action) {
    switch (action.type) {
      case types.SEARCH_FETCH:
        return action.searchType === type
          ? {
              hits:
                action.from > 0 ? [...state.hits, ...action.hits] : action.hits,
              total: action.total,
              query: action.query
            }
          : state;
      case types.RESET_RESULTS:
        return initType;
      default:
        return state;
    }
  };
}

export default combineReducers({
  query,
  results: combineReducers({
    cms: createTypeReducer("cms"),
    news: createTypeReducer("news"),
    media: createTypeReducer("media"),
    companies: createTypeReducer("companies")
  })
});
