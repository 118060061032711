import * as types from "./../types";

const initialState = [];

export default function timelineReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.TIMELINE_UPDATES_APPEND:
      const newState = [action.post, ...state];
      return newState;
    case types.TIMELINE_UPDATES_FLUSH:
      return [];
    default:
      return state;
  }
}
