import * as types from "./../types";

const initialState = { title: "" };

export default function navigationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.NAVIGATION_GO:
      return Object.assign({}, state, {
        location: action.location
      });
    case types.NAVIGATION_UPDATE_DOCUMENT_TITLE:
      return Object.assign({}, state, {
        title: action.title
      });
    default:
      return state;
  }
}
