import { combineReducers } from "redux";
import feedback from "./feedback";
import loginOverlay from "./loginOverlay";
import rns from "./rns";
import navigation from "./navigation";
import watchlist from "./watchlist";
import timeline from "./timeline";
import isServer from "./isServer";
import isRouter from "./isRouter";
import search from "./search";
import appRightMargin from "./appRightMargin";
import authentication from "./authentication";
import marketIndex from "./marketIndex";
import client from "./client";
import preferences from "./preferences";

const reducers = {
  ui: combineReducers({
    loginOverlay,
    feedback,
    rns,
    navigation,
    watchlist,
    timeline,
    isServer,
    isRouter,
    search,
    appRightMargin,
    authentication,
    marketIndex,
    client,
    preferences,
  }),
};

export default reducers;
