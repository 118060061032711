import * as types from "./../types";

export function add(text, status) {
  return {
    type: types.FEEDBACK_ADD,
    text,
    status
  };
}

export function remove() {
  return {
    type: types.FEEDBACK_CLEAR
  };
}
